@import "vars";

button,
select,
html,
textarea,
input,
body {
  font-family: "Calibri", Helvetica, Arial, sans-serif !important;
}

body {
  background-color: map_get($colors, lightGrey);
}

@media (min-width: 544px){
  .report-content {
    height: calc(100vh - 84px)
  }
}

@media (max-width: 991px) {
  .app-aside .left {
    position: fixed;
    width: 230px;
  }

  .text{
    font-size: 0.8rem;
  }
  .text-xs{
    font-size: 0.6rem;
  }
  .text-sm{
    font-size: 0.7rem;
  }
  .text-md{
    font-size: 1rem;
  }
  .text-lg{
    font-size: 1.2rem;
  }

  .text-2x{
    font-size: 2em;
  }
  .text-3x{
    font-size: 3em;
  }
  .text-4x{
    font-size: 4em;
  }
}

.navbar-brand{
  padding-top: 2px;
}

.nav-active-primary .nav-link.active, .nav-active-primary .nav > li.active > a {
  color: rgba(255, 255, 255, 1) !important;
  background-color: map-get($colors, dnas-rojo) !important;
}

.bg-sections-title {
  background-color: map-get($colors, dnas-rojo);
}

.color-dnas-title {
  color: map_get($colors, dnas-naranjaPrincipal)
}

md-tabs.md-default-theme .md-tab.md-active, md-tabs .md-tab.md-active, md-tabs.md-default-theme .md-tab.md-active md-icon, md-tabs .md-tab.md-active md-icon, md-tabs.md-default-theme .md-tab.md-focused, md-tabs .md-tab.md-focused, md-tabs.md-default-theme .md-tab.md-focused md-icon, md-tabs .md-tab.md-focused md-icon {
  color: map-get($colors, dnas-naranjaPrincipal);
}

md-tabs.md-default-theme md-ink-bar, md-tabs md-ink-bar {
  background-color: map-get($colors, dnas-naranjaPrincipal);
}

md-progress-linear.md-default-theme .md-container, md-progress-linear .md-container {
  background-color: map-get($colors, dnas-naranjaClaro1);
}

md-progress-linear.md-default-theme .md-bar, md-progress-linear .md-bar {
  background-color: map-get($colors, dnas-rojo);
}

md-input-container.md-input-focused label:not(.md-no-float), md-input-container.md-input-has-placeholder label:not(.md-no-float), md-input-container.md-input-has-value label:not(.md-no-float) {
  font-size: 17px;
}

.pace {
  .pace-progress {
    background: map_get($colors, dnas-naranjaPrincipal);
    height: 3px;
  }
}

md-checkbox.md-default-theme.md-checked .md-icon, md-checkbox.md-checked .md-icon {
  background-color: map-get($colors, dnas-naranjaPrincipal);
}

.md-button.md-default-theme.md-primary, .md-button.md-primary {
  color: rgb(0,0,0);
}

.tab-container{
  border-bottom: 1px solid #EEE;
}

.spinner {
  margin: auto;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 10px;
  height: 10px;
  margin: 0 2px;
  background-color: map-get($colors, dnas-naranjaPrincipal);

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0)
  }
  40% {
    -webkit-transform: scale(1.0)
  }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

.error-msg-container {
  border: 1px solid rgba(244, 67, 54, 1);
  border-radius: 5px;
  padding: 5px 10px;
  background-color: rgba(244, 67, 54, .3);
  color: rgba(244, 67, 54, 1);
}

.text-capitalize {
  text-transform: capitalize;
}

.streamline:after {
  display: none;
}

.bg-lightGrey {
  background-color: #f0f0f0;
}

.app-aside {
  z-index: 1;
}

.app-header{
  z-index: 50;
}

.title-icon {
  display: inline-block;
  font-size: 35px;
  line-height: 35px;
  padding: 5px;
  border-radius: 50%;
  border: 2px solid #FFF;
  width: 50px;
  height: 50px;
  color: #FFF;
  text-align: center;
}

.layout-container-fill {
  align-items: stretch;
}

.clickable {
  cursor: pointer;
}

md-input-container label {
  margin-bottom: 0;
}

md-dialog {
  min-width: 500px;
}

.list-item:hover {
  background-color: #ECEFF1;
}

.nav-pills .nav-link:hover {
  background-color: #CFD8DC;
}

md-input-container {
  margin-bottom: 0;
}

.md-panel.md-tooltip {
  background-color: rgba(33, 33, 33, 1);
  font-size: 12px;
  z-index: 99999;
}

md-toast {
  &.error-toast .md-toast-content {
    background-color: rgba(244, 67, 54, 1);
  }
  &.warning-toast .md-toast-content {
    background-color: rgb(239, 138, 19);
  }
  &.success-toast .md-toast-content {
    background-color: rgba(0, 150, 136, 1);
  }
}

.profile-options-menu li a > span {
  display: inline-block;
  width: 40px;
  white-space: nowrap;
}

.estimated-label{
  position: relative;
  left: -5px;
  top: -5px;
}

.no-br{
  white-space: nowrap;
}

.md-button.md-blank.md-fab {
  background-color: #FFF;
  color: #000;
}

.md-button.md-fab:not([disabled]):hover {
  background-color: #CFD8DC;
}

.md-button.md-fab.md-micro {
  line-height: 30px;
  width: 30px;
  height: 30px;
  min-height: 30px;
}

md-card {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 -1px 0px rgba(0, 0, 0, 0.02);

  md-card-content.no-padding {
    padding: 0;
  }
}

.variables-table > tbody > tr > td, .variables-table > tbody > tr > th, .variables-table > tfoot > tr > td, .variables-table > tfoot > tr > th {
  border-color: rgba(120, 130, 140, 0.1);
}

.variables-table {
  tbody td, th {
    vertical-align: middle;
  }
}

md-tabs.tabs-in-white md-tabs-wrapper {
  background-color: #FFF;
}

md-toolbar.md-default-theme:not(.md-menu-toolbar), md-toolbar:not(.md-menu-toolbar) {
  background-color: #FFF;
  border-bottom: 2px solid map_get($colors, dnas-naranjaPrincipal);
  color: map_get($colors, dnas-naranjaPrincipal);
}

.false-float-label {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
}

$dialog-sizes: (fullscreen:100%, lg: 90%, md: 70%, sm: 50%);

@each $name, $size in $dialog-sizes {
  md-dialog.#{$name}-dialog {
    max-width: #{$size};
    max-height: #{$size};
    width: #{$size};
    height: #{$size};
  }
}

md-dialog.fullscreen-dialog {
  border-radius: 0;
}

md-dialog.height-no-limited {
  max-height: initial;
  height: initial;
}

.md-button.md-icon-button-xs {
  margin: 2px;
  min-width: 36px;
  padding: 0;
  line-height: initial;
}

.mce-panel {
  border: 0 solid rgba(0, 0, 0, 0.05) !important;
  background-color: #FFF!important;
}

.mce-btn {
  background-color: #FFF!important;
}

.mce-stack-layout-item {
  border-bottom: 1px solid #EEE;
}

.mce-statusbar .mce-container-body {
  border: 1px solid #EEE;
  background-color: #FFF;
}

/*Container, container body, iframe*/
.mce-tinymce, .mce-container-body, #code_ifr {
  min-height: 100% !important;
}
/*Container body*/
.mce-container-body {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
/*Editing area*/
.mce-container-body .mce-edit-area {
  position: absolute;
  top: 69px;
  bottom: 37px;
  left: 0;
  right: 0;
}
/*Footer*/
.mce-tinymce .mce-statusbar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.mce-container-body iframe{
  height: 100%!important;
  overflow: auto;
}

.mce-menu {
  background-color: #FCFCFC;
  border: 1px solid #CCC!important;
}

.search-broker {
  border-bottom: 1px solid rgb(204, 204, 204);
}

md-checkbox.no-margin {
  margin: 0;
}

md-dialog .md-dialog-content.no-padding {
  padding: 0;
}

md-select.md-default-theme[disabled] .md-select-icon,
md-select[disabled] .md-select-icon,
md-select.md-default-theme[disabled] .md-select-value,
md-select[disabled] .md-select-value,
md-select.md-default-theme[disabled] .md-select-value.md-select-placeholder,
md-select[disabled] .md-select-value.md-select-placeholder{
  color: rgba(0, 0, 0, 0.9);
}

[disabled] md-input-container.md-default-theme .md-input,
[disabled] md-input-container .md-input,
md-input-container.md-default-theme .md-input[disabled],
md-input-container .md-input[disabled]{
  border-bottom-color: transparent;
  color: rgba(0, 0, 0, 0.9);
  opacity: 1;
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.38) 0, rgba(0, 0, 0, 0.38) 33%, transparent 0);
  background-image: -ms-linear-gradient(left, transparent 0, rgba(0, 0, 0, 0.38) 100%);
}

.md-button.md-default-theme.md-fab[disabled], .md-button.md-fab[disabled], .md-button.md-default-theme.md-raised[disabled], .md-button.md-raised[disabled] {
  cursor: not-allowed;
}

.md-icon-button + .md-datepicker-input-container {
  margin-left: 0;
}

._md-datepicker-floating-label._md-datepicker-has-calendar-icon > label:not(.md-no-float):not(.md-container-ignore) {
  right: 30px;
}

.md-datepicker-calendar-icon {
  color: map_get($colors, dnas-naranjaPrincipal);
}

.table > tbody > tr > td, .table > tbody > tr > th, .table > tfoot > tr > td, .table > tfoot > tr > th {
  border-color: rgba(120, 130, 140, 0.1);
}

.table.goal-tracking {
  margin-bottom: 0;
  border: none;
}

.table.goal-tracking td {
  padding: 5px;
  vertical-align: middle;
}

.table.goal-tracking th{
  padding: 3px;
  vertical-align: middle;
}

.table > tbody > tr > th.no-border {
  border: none;
}

.md-chips {
  font-size: 12px;

  md-chip {
    border-radius: 2px;
  }
}

md-chips md-chip {
  background: #EEE;
  color: rgb(66, 66, 66);

  strong {
    font-weight: normal;
  }
}

.box-header.b-b.collapsed {
  border-bottom: none;
}

.broker-logo{
  width: 300px;
}

ul.ivh-treeview {
  list-style-type: none;
  padding-left: 0;

  ul.ivh-treeview {
    padding-left: 40px;
  }

  .ivh-treeview-toggle {
    cursor: pointer;
  }

  .ivh-treeview-node-leaf .ivh-treeview-toggle {
    cursor: auto;
  }
}

md-autocomplete md-autocomplete-wrap.md-whiteframe-z1{
  box-shadow: none;
}

.md-autocomplete-suggestions-container.md-default-theme li .highlight, .md-autocomplete-suggestions-container li .highlight {
  color: map-get($colors, dnas-naranjaPrincipal);
}

md-autocomplete .md-show-clear-button button{
  order: 3;
}


#vertical-container{
  width: 100%;
  height: 400px;
}

.floatThead-wrapper {
  position: initial !important;
}

.v-a-top, .table.goal-tracking th.v-a-top{
  vertical-align: top;
}

.table tbody+tbody {
  border-top: none;
}

.mce-primary button, .mce-primary button i {
  color: #333!important;
}

.home-section {
  a {
    border: 3px solid transparent;
    border-radius: 6px;

    &:hover{
      border-color: map_get($colors, 'dnas-rojo');
      background-color: #FFF;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    }
  }

  i {
    font-size: 800%;
    color: map_get($colors, 'dnas-naranjaClaro2');
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  }

  h4 {
    color: map_get($colors, 'dnas-grisClaro');
    font-size: 130%
  }
}

.ui-state-highligh{
  height: 50px;
  width: 100%;
  border: 1px solid red;
}

.no-overflow{
  overflow: hidden;
}

.goal-header{
  max-width: calc(100% - 50px);
}

.broker-plan-desc{
  height: 110px;
  text-overflow: ellipsis;
  overflow: scroll;
  word-wrap: break-word;
}

.dropdown-menu.as-combo{
  width: 100%;
  border-radius: 0;
  border-top-color: #FFF;
  border-left-color: #FFF;
  border-right-color: #FFF;
  box-shadow: 0 5px 10px rgba(0,0,0,.3);
}

.bg-card-company{
  position:absolute;
  bottom: 120px;
  right: 30px;
}

.small-scroll::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.small-scroll::-webkit-scrollbar-track {
  background-color: rgba(0,0,0,0);
  border-radius: 10px;
}

.small-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0,0,0,.2);
}

.box-header {
  padding: 14px;

  small {
    opacity: 1;
    /*font-weight: 600;*/
  }
}

.box-header.expandable {
  padding: 14px 14px 14px 5px;
}

.goal-status{
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding: 0;
  opacity: .8;

  i{
    margin: 0 5px;
  }

  &.left{
    left: 0;
    right: auto;
  }
}

.full-height{
  height: 100%;
}

.min-full-height{
  min-height: 100%;
}

.full-width{
  width: 100%;
}

.app-selected{
  background-color: #FFF!important;
  color: map_get($colors, 'dnas-rojo');
}

.box-shadow-inverted{
  box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.03), 0 -1px 0 rgba(0, 0, 0, 0.03);
}

.var-pill{
  border: 1px solid #CCC;
  padding: 5px 10px;
  border-radius: 6px;
}

.maximize-btn{
  background-color: rgba(255,255,255,.3);
  color: rgba(255,255,255,.8);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  position: relative;
  left: -11px;
  height: 30px;
  width: 20px;
  line-height: 33px;
  text-align: center;
}

.login-bg{
  background: url('../assets/images/bg-login.png') no-repeat;
  background-size: cover;
}

.docfile-item{
  max-width: 100px;
  padding: 10px;
  /*border: 1px solid #EEE;
  border-radius: 5px;*/
}

.ui-state-highlight {
  height: 50px;
  width: 100%;
  border: 2px solid pink;
  background-color: #f0f0f0;
}

.btn-plan-hidden{
  font-size: 20px;
  line-height: 12px;
  border: 1px solid #DDD;
  border-radius: 4px;
  width: 65px;
  min-width: 65px;
}

.bg-building{
  background: repeating-linear-gradient(
                  135deg,
                  map_get($colors, white) 20px,
                  map_get($colors, white) 40px,
                  rgba(map_get($colors, dnas-naranjaPrincipal),.5) 40px,
                  rgba(map_get($colors, dnas-naranjaPrincipal),.5) 60px
  );
}

.box-with-border{
  border: 1px solid #EEE;
}

p a {
  color: #0275d8;
  text-decoration: underline;
}

.color-informative{
  color: #000;
}

.goal-tracking .color-informative {
  color: #0275d8 !important;
}

.table thead th.v-a-middle, .table thead td.v-a-middle{
  vertical-align: middle;
}

.box-header.inner-zero{
  padding: 0;
}

.table-bordered thead td, .table-bordered thead th {
  border-bottom-width: 1px;
}

.table td.inner-zero, .table th.inner-zero{
  padding: 0;
}

.box-radius-0x{
  border-radius: 0;
}

.operator-selector-btn{
  padding: 5px;
  min-width: 44px;
  border-radius: 0;
}

.b-0x{
  border-width: 0;
}

.template-error{
  label {
    color: red;
  }

  select {
    border-color: red !important;
  }
}

.profit-pill {
  border-radius: 20px 0 0 20px;
  padding-left: 30px;
}

@media (max-width: 599px) {
  .profit-pill{
    border-radius: 0;
    padding-left: 0;
    background: none!important;
  }

  .profit-pill-negative{
    background: none!important;
  }
}

.profit-table input{
  padding: 0 0 0 5px;
  min-width: 75px;
}

/*.goal-required {
  border: 1px solid #ef8a13;
}*/

.profit-levels-table{
  td, th{
    vertical-align: middle;
  }
}

.goal-header {
  max-width: 100%;
}

.overflow-auto{
  overflow: auto;
}

md-radio-button.inline{
  display: inline-block;
  margin-right: 20px;
}