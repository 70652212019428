$colors: (turquoise: rgb(26, 188, 156), emerland: rgb(46, 204, 113), peterriver: rgb(52, 152, 219), amethyst: rgb(155, 89, 182), wetasphalt: rgb(52, 73, 94),
        greensea: rgb(22, 160, 133), nephritis: rgb(39, 174, 96), belizehole: rgb(41, 128, 185), wisteria: rgb(142, 68, 173), midnightblue: rgb(44, 62, 80),
        sunflower:rgb(241, 196, 15), carrot: rgb(230, 126, 34), alizarin: rgb(231, 76, 60), clouds: rgb(236, 240, 241), concrete: rgb(149, 165, 166),
        pumpkin: rgb(211, 84, 0), pomegranate: rgb(192, 57, 43), silver: rgb(189, 195, 199), asbestos: rgb(127, 140, 141),
  //Material Design
        red: rgb(244, 67, 54), pink: rgb(233, 30, 99), purple: rgb(156, 39, 176), deepPurple: rgb(103, 58, 183), indigo: rgb(63, 81, 181),
        blue:rgb(33, 150, 243), lightBlue: rgb(3, 169, 244), cyan: rgb(0, 188, 212), teal: rgb(0, 150, 136), green: rgb(76, 175, 80),
        lightGreen: rgb(139, 195, 74), lime: rgb(205, 220, 57), yellow: rgb(255, 235, 59), amber: rgb(255, 193, 7), orange: rgb(255, 152, 0),
        deepOrange: rgb(255, 87, 34), brown: rgb(121, 85, 72), grey: rgb(158, 158, 158), blueGrey: rgb(96, 125, 139),
        white: rgb(255, 255, 255), black: rgb(0,0,0), lightGrey: #F0F0F0, softerGrey: #F4F4F4, greenSoft: #E9F5E9, greenMedium: #81c784,
        yellowSoft: #FCF3CE, yellowMedium: #F0C30E, redSoft: #FFE5E5, redMedium: #FF6565,
  //DNAS
        dnas-grisPrincipal: rgb(50, 47, 47), dnas-grisClaro: rgb(146, 141, 139),
        dnas-naranjaClaro1: rgb(249, 186, 0), dnas-naranjaClaro2: rgb(245, 168, 1), dnas-naranjaPrincipal: rgb(239, 138, 19), dnas-rojo: rgb(200, 0 ,24) );

$margin-sizes: (zero:0, nano: 5, micro: 10, xxs: 15, xs: 30, sm: 45, md: 60, lg:90, xl:120);
$directions: "top", "right", 'bottom', 'left';
$percents: 5, 10, 15 ,20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100